<template>
  <app-card-link-background
    class="@container/card"
    :style="{
      width: width ? `${width}px` : undefined,
    }"
    :to="event._links.page"
  >
    <app-card class="group relative overflow-hidden" tag="section">
      <app-image
        :alt="event.course.teacher.full_name!"
        class="absolute inset-0 h-full w-full object-cover transition duration-500 group-hover:scale-110"
        :class="{
          'hidden @lg/card:!hidden sm:block': !!event.course.teacher.cover_url,
        }"
        :src="event.course.teacher.avatar_url"
      />

      <app-image
        v-if="event.course.teacher.cover_url"
        :alt="event.course.teacher.full_name!"
        class="absolute inset-0 h-full w-full object-cover transition duration-500 group-hover:scale-110 @lg/card:!block sm:hidden"
        :src="event.course.teacher.cover_url"
      />

      <div
        class="relative flex aspect-video flex-col items-start justify-between gap-6 bg-gradient-to-t from-black/70 p-3 text-white @lg/card:!aspect-video @lg/card:p-6 sm:aspect-[--ratio]"
        :style="{
          '--ratio': ratio,
        }"
      >
        <course-session-date-chip
          class="!bg-white !text-black"
          :session="event"
          size="small"
        />
        <div>
          <h3 class="mb-2 line-clamp-3 font-title text-xl/snug">
            {{ event.course.title }}
          </h3>
          <p class="text-lg font-semibold">
            {{ event.course.teacher.full_name }}
          </p>
          <p>{{ event.course.teacher.job }}</p>
          <teacher-companie-logos :companies="event.course.teacher.companies" />
        </div>
      </div>
    </app-card>
  </app-card-link-background>
</template>

<script lang="ts" setup>
import type { EventListView } from "~/business-areas/event/composables/event-list.hook";
import {
  type CarouselProvidedState,
  CarouselProvideKey,
} from "~/shared/carousel/carousel.model";

withDefaults(
  defineProps<{
    event: EventListView;
    ratio?: number;
  }>(),
  {
    ratio: 0.8,
  },
);

const width = computed(
  () =>
    inject<CarouselProvidedState | null>(CarouselProvideKey, null)?.itemWidth,
);
</script>
