import { startOfTomorrow } from "date-fns";

import type { RouteLocationRaw } from "#vue-router";
import type { EventListApiView } from "~~/model/event.model";

export type EventFilters = {
  from?: string;
  to?: string;
  exclude?: DatabaseTable<"course_sessions">["id"][];
  has_replay?: boolean;
};

export type EventListView = EventListApiView & {
  _links: {
    page: RouteLocationRaw;
  };
};

export function useEventList(filters?: EventFilters) {
  const cache = useEventCache();

  return useLazyFetch("/api/events", {
    query: filters,
    transform(data) {
      const views =
        data?.map((event) => {
          const view: EventListView = {
            ...event,
            _links: {
              page: {
                name: "events-slug",
                params: {
                  slug: event.slug,
                },
              },
            },
          };
          return view;
        }) ?? [];

      cache.setMultiple(views);

      return views;
    },
    default: () => [],
  });
}

export function useUpcomingEventList(
  filters?: Omit<EventFilters, "from" | "to">,
) {
  return useEventList({
    from: startOfTomorrow().toISOString(),
    ...filters,
  });
}
